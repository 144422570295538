import React from 'react';
import Gallery from '../Gallery';

const ProductGallery = ({ imgs, listOfThings }) => (
  <section className="container">
    {listOfThings ? (
      <div className="mt-5">
        <ul>
          {listOfThings.map((v) => (
            <li key={v}>
              {v}
            </li>
          ))}
        </ul>
      </div>
    ) : null}
    <div className="columns">
      <div className="column">
        {/* <h1 className="ahjud-font-syncopate has-text-centered is-size-3">Kaminad</h1> */}
      </div>
    </div>
    <div className="columns">
      <div className="column mt-5">
        <Gallery photos={imgs} />
      </div>
    </div>
  </section>
);

export default ProductGallery;
